$gaps : (
	'10' : rem(10px),
	'15' : rem(15px),
	'20' : rem(20px),
	'30' : rem(30px)
);

/* GRID/FLEX GAPS */
@each $gap, $i in $gaps {
	.gap-#{$gap}, h#{$gap}{
		gap: $i;
	}	
}
@mixin mainradius() {
	border-radius: rem(20px);
	@include media-breakpoint-up(xxl){
        border-radius: rem(40px);
    }
}
.main-radius{
	@include mainradius();
}
.main-padding{
	padding: rem(40px);
	@include media-breakpoint-up(lg){
        padding: rem(60px);
    }
}
.shaped-bg{
    background-image: url(../images/arc-shape.svg);
    background-position: top left;
    background-repeat: no-repeat;
    @include media-breakpoint-down(lg){
        background-size: contain;
    }
}

.obj-cover{
    width: 100%;
    height: 100%;
    object-fit: cover;
}