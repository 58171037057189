$title-sizes : (
	'1' : rem(110px),
	'2' : rem(80px),
	'3' : rem(22px),
	'4' : rem(18px),
);
$title-sizes-tablet : (
	'1' : rem(70px),
	'2' : rem(50px),
	'3' : rem(22px),
	'4' : rem(18px),
);
$title-sizes-mobile : (
	'1' : rem(35px),
	'2' : rem(28px),
	'3' : rem(18px),
	'4' : rem(14px),
);

@each $hsize, $i in $title-sizes {
	.t-#{$hsize}, h#{$hsize}{
		font-size: $i;
	}	
}
@include media-breakpoint-down(lg){
    /* TITLE CLASSES .t1, */
    @each $hsize, $i in $title-sizes-tablet {
        .t-#{$hsize}, h#{$hsize}{
            font-size: $i;
        }	
    }
}
@include media-breakpoint-down(md){
    /* TITLE CLASSES .t1, */
    @each $hsize, $i in $title-sizes-mobile {
        .t-#{$hsize}, h#{$hsize}{
            font-size: $i;
        }	
    }
}

$text-sizes: (
	'1' : rem(38px),
    '2' : rem(22px),
    '3' : rem(18px),
    '4' : rem(14px)
);
$text-sizes-tablet : (
	'1' : rem(28px),
    '2' : rem(22px),
    '3' : rem(18px),
    '4' : rem(14px)
);
$text-sizes-mobile : (
	'1' : rem(18px),
    '2' : rem(17px),
    '3' : rem(16px),
    '4' : rem(14px)
);

@each $tsize, $i in $text-sizes {
	.desc-#{$tsize} {
		font-size: $i;
	}	
}
@include media-breakpoint-down(lg){
    /* TITLE CLASSES .t1, */
    @each $hsize, $i in $text-sizes-tablet {
        .desc-#{$hsize}{
            font-size: $i;
        }	
    }
}
@include media-breakpoint-down(md){
    /* TITLE CLASSES .t1, */
    @each $hsize, $i in $text-sizes-mobile {
        .desc-#{$hsize}{
            font-size: $i;
        }	
    }
}

/* LINE HEIGHT */
$lh-sizes: (
	'100' : 1em,
    '125' : 1.25em,
	'150' : 1.5em,
    '175' : 1.75em,
	'200' : 2em,
	'250' : 2.5em,
    '300' : 3em
);
/* LINE HEIGHT CLASSES .lh-10 */
@each $lhsize, $i in $lh-sizes {
	.lh-#{$lhsize} {
		line-height: $i;
	}	
}

.entry-content {
	h1, h2, h3, h4, h5, h6 {
		margin-bottom: 1em;
		padding-bottom: 0.5em;
		border-bottom: 1px solid $gray-300;
	}
	p,
    li {
        line-height: inherit;
    }

    ul:not(.wp-block-gallery):not(.blocks-gallery-grid){
        list-style: outside disc;
        li {
            margin-left: 20px;
        }
    }
	ol {
        list-style: outside decimal;
		li {
            margin-left: 20px;
        }
    }
    p, ul, ol {
        margin-bottom: 1.5rem;
		padding: 0;
        &:first-child {
            margin-top: 0;
        }
    }
    small, 
    .font_small {font-size: 0.833em;}

    pre {
        padding: 1em 1.5em;
        background: lighten($gray-300,10%);
        border:1px solid $gray-300;
    }
    code {
        font-size: 0.875em;
        font-family: "Lucida Console", Monaco, monospace;
    }
    table {
        margin:1.5em 0;
        width: 100%;
        border:1px solid $gray-300;
        @media screen and (max-width: 650px) {
            table {
                overflow-x: auto;
                display: block;
            }
        }
        th {
            font-weight: 700;
        }
        th,
        td {
            padding: 0.5em;
            border:1px solid $gray-300;
        }
    }
    blockquote {
    	padding-left: 2rem;
    	position: relative;
    	margin: 3rem 0;
    	&:before {
    		position: absolute;
    		top: 0;
    		left: 0;
    		bottom: 0;
    		content: "";
    		width: 5px;
    		background-color: $primary;
    		height: 100%;
    		border-radius: 5px;
    	}
    }
    .wp-block-image {
        margin: 1.5rem 0;
    	figcaption {
    		text-align: center;
    		color: $gray-300;
    	}
    }
}

input[type="checkbox"]{
    flex:0 0 auto;
    z-index: 2;
    position: relative;
    margin: 0 .5rem 0 0;
    cursor: pointer;
    height: 20px;
    width: 20px;
    overflow: hidden;
    background-color: $white;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    transition: .2s all ease-in-out;
    outline:none;
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8' standalone='no'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' preserveAspectRatio='xMidYMid meet' viewBox='0 0 78 78' width='78' height='78'%3E%3Cdefs%3E%3Cpath d='M0 0L78 0L78 78L0 78L0 0Z' id='f1iqBdO2H'%3E%3C/path%3E%3CclipPath id='clipc1PPr0qfP'%3E%3Cuse xlink:href='%23f1iqBdO2H' opacity='1'%3E%3C/use%3E%3C/clipPath%3E%3C/defs%3E%3Cg%3E%3Cg%3E%3Cg%3E%3Cuse xlink:href='%23f1iqBdO2H' opacity='1' fill='%23ffffff' fill-opacity='0'%3E%3C/use%3E%3Cg clip-path='url(%23clipc1PPr0qfP)'%3E%3Cuse xlink:href='%23f1iqBdO2H' opacity='1' fill-opacity='0' stroke='%23094d63' stroke-width='2' stroke-opacity='1'%3E%3C/use%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    transition: .2s all ease-in-out;
    outline:none;
    &:checked {
        background-color: white;
        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8' standalone='no'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' preserveAspectRatio='xMidYMid meet' viewBox='0 0 78 78' width='78' height='78'%3E%3Cdefs%3E%3Cpath d='M30.91 64.09C30.56 64.44 29.99 64.44 29.65 64.09C27.39 61.83 9.35 43.79 7.1 41.54C6.75 41.19 6.75 40.62 7.1 40.27C7.65 39.73 12.03 35.34 12.58 34.79C12.93 34.45 13.49 34.45 13.84 34.79C14.94 35.89 20.42 41.37 30.28 51.23C50.44 31.07 61.64 19.87 63.88 17.63C64.23 17.28 64.79 17.28 65.14 17.63C65.69 18.18 70.07 22.56 70.62 23.11C70.97 23.46 70.97 24.03 70.62 24.38C62.68 32.32 34.88 60.11 30.91 64.09Z' id='e4Vx1UcBCr'%3E%3C/path%3E%3Cpath d='M0 0L78 0L78 78L0 78L0 0Z' id='aH1GbixXH'%3E%3C/path%3E%3CclipPath id='clipbcqB1dMhT'%3E%3Cuse xlink:href='%23aH1GbixXH' opacity='1'%3E%3C/use%3E%3C/clipPath%3E%3C/defs%3E%3Cg%3E%3Cg%3E%3Cg%3E%3Cuse xlink:href='%23e4Vx1UcBCr' opacity='1' fill='%23007ac3' fill-opacity='1'%3E%3C/use%3E%3Cg%3E%3Cuse xlink:href='%23e4Vx1UcBCr' opacity='1' fill-opacity='0' stroke='%23000000' stroke-width='1' stroke-opacity='0'%3E%3C/use%3E%3C/g%3E%3C/g%3E%3Cg%3E%3Cuse xlink:href='%23aH1GbixXH' opacity='1' fill='%23ffffff' fill-opacity='0'%3E%3C/use%3E%3Cg clip-path='url(%23clipbcqB1dMhT)'%3E%3Cuse xlink:href='%23aH1GbixXH' opacity='1' fill-opacity='0' stroke='%23094d63' stroke-width='2' stroke-opacity='1'%3E%3C/use%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        transition: .2s all ease-in-out;
    }
}