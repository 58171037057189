.site-header{
    z-index: 999;
    &__wrapper{
        position: relative;
        border-radius: 0 0 rem(20px) rem(20px);
        overflow: hidden;
        &::before{
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: transparent;
            backdrop-filter: blur(30px);
            --webkit-backdrop-filter: blur(30px);
            background-color: rgba(255, 255, 255, 0.15);
            display: block;
        }
        height: rem(45px);
        @include media-breakpoint-up(md){
            height: rem(65px);
        }
        @include media-breakpoint-up(xl){
            height: rem(110px);
        }
    }
    .site-logo{
        width: rem(132px);
        margin-top: -.5em;
        @include media-breakpoint-up(md){
            width: rem(152px);
            margin-top: -1em;
        }
        @include media-breakpoint-up(xxl){
            margin-top: -1em;
            width: rem(238px);
        }
        
    }
    &__menu {
        li {
            span {
                cursor: pointer;
                @include media-breakpoint-down(xxl){
                   font-size:rem(11px);
                }
            }
        }
    }
}

.form-toggle {
    cursor:pointer;
}