@font-face {
    font-family: 'GT America Expanded';
    src: url('../fonts/GTAmerica-ExpandedLight.woff2') format('woff2'),
        url('../fonts/GTAmerica-ExpandedLight.woff') format('woff'),
        url('../fonts/GTAmerica-ExpandedLight.svg#GTAmerica-ExpandedLight') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'GT America Expanded';
    src: url('../fonts/GTAmerica-ExpandedRegular.woff2') format('woff2'),
        url('../fonts/GTAmerica-ExpandedRegular.woff') format('woff'),
        url('../fonts/GTAmerica-ExpandedRegular.svg#GTAmerica-ExpandedRegular') format('svg');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'GT America Expanded';
    src: url('../fonts/GTAmerica-ExpandedMedium.woff2') format('woff2'),
        url('../fonts/GTAmerica-ExpandedMedium.woff') format('woff'),
        url('../fonts/GTAmerica-ExpandedMedium.svg#GTAmerica-ExpandedMedium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'GT America Expanded';
    src: url('../fonts/GTAmerica-ExpandedBold.woff2') format('woff2'),
        url('../fonts/GTAmerica-ExpandedBold.woff') format('woff'),
        url('../fonts/GTAmerica-ExpandedBold.svg#GTAmerica-ExpandedBold') format('svg');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'GT America Extended';
    src: url('../fonts/GTAmerica-ExtendedLight.woff2') format('woff2'),
        url('../fonts/GTAmerica-ExtendedLight.woff') format('woff'),
        url('../fonts/GTAmerica-ExtendedLight.svg#GTAmerica-ExtendedLight') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'GT America Extended';
    src: url('../fonts/GTAmerica-ExtendedRegular.woff2') format('woff2'),
        url('../fonts/GTAmerica-ExtendedRegular.woff') format('woff'),
        url('../fonts/GTAmerica-ExtendedRegular.svg#GTAmerica-ExtendedRegular') format('svg');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'GT America Extended';
    src: url('../fonts/GTAmerica-ExtendedBold.woff2') format('woff2'),
        url('../fonts/GTAmerica-ExtendedBold.woff') format('woff'),
        url('../fonts/GTAmerica-ExtendedBold.svg#GTAmerica-ExtendedBold') format('svg');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@mixin gtaExp( $weight: false) {
	font-family: 'GT America Expanded', sans-serif;
	@if $weight { font-weight: $weight; }
}

$weights_gtaExp : 300, 400, 500, 700;
@each $weight in $weights_gtaExp {
	.gtaExp-#{$weight} {
		font-family: 'GT America Expanded', sans-serif !important;
        font-weight: $weight !important;
	}	
} 
@mixin gtaExt( $weight: false) {
	font-family: 'GT America Extended', sans-serif;
	@if $weight { font-weight: $weight; }
}

$weights_gtaExt : 300, 400, 700;
@each $weight in $weights_gtaExt {
	.gtaExt-#{$weight} {
		font-family: 'GT America Extended', sans-serif !important;
        font-weight: $weight !important;
	}	
} 