// Use this file to overwrite the basic Bootstrap variables and add your own variables
// To overwrite a Bootstrap variable you don´t have to touch the Bootstrap folder. 
// Just copy a variable from /sass/bootstrap/_variables.scss, paste it here and edit the value.
// As example you find one of the most important Bootstrap variable below: $brand-primary.
// Thats the ugly Bootstrap blue used for all the links, primary buttons etc.
// Just add your color code here, compile the theme.scss file to change this color everywhere


//
// Color system
//

$black: #000;
$white: #fff;
$gray: #707070;
$darkgray: #242424;
$lightgray: #F5F5F5;

$primary: #00CCBB;
$green: #99CC00;
$yellow: #E6AC00;
$red: #CC1100;

$theme-colors: (
  "primary": $primary,
  "green": $green,
  "yellow": $yellow,
  "red": $red,
  "darkgray": $darkgray,
  "lightgray": $lightgray,
);

$body-bg : #022A32;

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 5,
  7: $spacer * 6.75,
  8: $spacer * 12
);
$enable-negative-margins: true;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1530px
);