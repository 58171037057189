.site-footer {
    br{
        @include media-breakpoint-down(lg){
            display: block;
        }
    }
    &-top {
        @include media-breakpoint-up(lg){
            border-color: $primary;
            border-width: 2px;
            border-top-style: solid;
            border-bottom-style: solid;
            [class^='col'] {
                &:nth-of-type(2){
                    border-left: 2px solid $primary;
                }
            }
        }
        &__item{
            @include media-breakpoint-up(lg){
                figure{
                    min-height: 46px;
                }
            }
            &-contacts{
                @include media-breakpoint-between(md,xl){
                    font-size: .8;
                }
            }
        }
        &__grid {
            display: grid;
            grid-template-columns: 2fr 2fr;
            gap:3rem;
            @include media-breakpoint-down(sm){
                grid-template-columns: 1fr;
                text-align: center;
            }
        }
        
        &__sidegrid {
            display: grid;
            gap:3rem;

            @include media-breakpoint-down(lg){
                grid-template-columns: 2fr 2fr;
            }
            @include media-breakpoint-down(sm){
                grid-template-columns: 1fr;
                text-align: center;
            }
        }
        
        
    }  
    &__logo {
        @include media-breakpoint-down(lg){
            border:2px solid $primary;
            border-bottom: none;
            display: flex;
            justify-content: center;
        }
    }
    &__partners {
        @include media-breakpoint-down(lg){
            border:2px solid $primary;
            padding: 2rem;
        }
    }
    &-closure {
        @include media-breakpoint-down(lg){
            
            border:2px solid $primary;
            border-top: none;
            padding:2rem;
        }
    }
}