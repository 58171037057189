.full-width-carousel {
    .swiper-slide {
        display: block;
        width: auto !important;
        max-width: 100%;
        img {
            height: 70vh;
            width: auto;
            max-width: 100%;
            object-fit: cover;
            @include media-breakpoint-down(lg){
                height: 50vh;
            }
        }
    }
}

.swiper-pagination {
    display: flex;
    justify-content: center;
    gap:0.5rem;
    &-bullet {
        flex:0 0 auto;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: $primary;
        &:hover,
        &-active {
            background-color: $white;
            cursor: pointer;
        }
    }
}