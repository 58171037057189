.cta {
    line-height: 1;
    padding:rem(18px) rem(98px);
    @extend .t-3;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 9999px;
    overflow: hidden;
    text-decoration: none;
    position: relative;
    @include media-breakpoint-down(md){
        font-size: rem(14px);
        padding:rem(16px) rem(38px);
    }
    span {
        position: relative;
        z-index: 2;
    }
    &::before {
        content: "";
        position: absolute;
        z-index: 1;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: .3s linear all;
    }
    &:hover {
        &::before {
            opacity: 1;
            transition: .3s linear all;
        }
    }
    &.--filled-primary {
        background: linear-gradient(90deg, $primary 0%, #022a32 100%);
        background-repeat: no-repeat;
        color:$white;
        &:hover {
            &::before {
                background-color: $primary;
            }
            color: $white;
        }
    }
    &.--filled-yellow {
        background: linear-gradient(90deg, $yellow 0%, #320401 100%);
        background-repeat: no-repeat;
        color:$white;
        &:hover {
            &::before {
                background-color: $yellow;
            }
            color: $white;
        }
    }
    &.--filled-green {
        background: linear-gradient(90deg, $green 0%, #022a32 100%);
        background-repeat: no-repeat;
        color:$white;
        &:hover {
            &::before {
                background-color: $green;
            }
            color: $white;
        }
    }
    &.--filled-dark {
        background: #002A33;
        background-repeat: no-repeat;
        color:$white;
        &:hover {
            &::before {
                background-color: $primary;
            }
            color: $white;
        }
    }
}