:root {
    --bs-gutter-x: 1.5rem;
}

figure {
    margin: 0;
}
img{
    max-width: 100%;
    height: auto;
}
br{
    @include media-breakpoint-down(md){
        display: none;
    }
}

.container-medium {
    max-width: 1160px;
}

/* INTRO */
.lp-intro{
    position: relative;
    @keyframes introDownPari {
        from {
            transform: translateY(-100%);
        }
        to {
            transform: translateY(0);
        }
    }
    @keyframes introUpPari {
        from {
            transform: translateY(0%);
        }
        to {
            transform: translateY(-100%);
        }
    }
    @keyframes introUpDispari {
        from {
            transform: translateY(100%);
        }
        to {
            transform: translateY(0%);
        }
    }
    @keyframes introDownDispari {
        from {
            transform: translateY(0);
        }
        to {
            transform: translateY(100%);
        }
    }
    &__anim-block{
        position: absolute;
        width: 50%;
        height: 90%;
        background: linear-gradient(#21cbbb 0%, #022a33 100%);
        &.--1, &.--3, &.--5{
            animation-name: introDownDispari;
            animation-duration: 1.5s;
            animation-fill-mode: forwards;
        }
        &.--2, &.--4{
            animation-name: introUpPari;
            animation-duration: 1.5s;
            animation-fill-mode: forwards;
            background: linear-gradient(#022a33 0%, #21cbbb 100%);
        }
        @include media-breakpoint-down(md){
            &.--1{
                bottom: 0;
                left: 0;
            }
            &.--2{
                top: 0;
                right: 0;
            }
        }
        @include media-breakpoint-up(md){
            width: calc(100% / 3);
            height: 60%;
            &.--1{
                top: 0;
                left: 0;
            }
            &.--2{
                bottom: 0;
                left: calc(100% / 3 * 1);
                
            }
            &.--3{
                top: 0;
                right: 0;
            }
        }
        @include media-breakpoint-up(xl){
            width: 20%;
            &.--1{
                height: 30%;
                top: 35%;
                left: 0;
            }
            &.--2{
                height: 70%;
                top: 20%;
                left: calc(100% / 5 * 1);
            }
            &.--3{
                height: 30%;
                top: 0;
                left: calc(100% / 5 * 2);
            }
            &.--4{
                height: 55%;
                bottom: 0;
                left: calc(100% / 5 * 3);
            }
            &.--5{
                height: 45%;
                top: 0;
                right: 0;
            }
        }
    }
    &.visible{
        .lp-intro__anim-block{
            &.--1, &.--3, &.--5{
                animation-name: introUpDispari;
                animation-duration: 1.5s;
                animation-fill-mode: forwards;
            }
            &.--2, &.--4{
                animation-name: introDownPari;
                animation-duration: 1.5s;
                animation-fill-mode: forwards;
            }
        }
    }
}
.full-width-figure{
    height: 75vh;
    @include media-breakpoint-down(lg){
        height: 50vh;
    }
    &__img{
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
    &__anim-img{
        position: absolute;
        width: auto;
        &.--1{
            top: 0;
            right: 0;
        }
        &.--2{
            bottom: 0;
            right: 0;
        }
    }
}
.lp-contesto {
    &__header {
        position: relative;
        &::before {
            content: "";
            width: 30vw;
            height: 100%;
            left: 0;
            top: 0;
            position: absolute;
            background: linear-gradient(90deg, #21cbbb 0%, rgba(2, 42, 50, 0) 100%);
            z-index: 0;
            @include media-breakpoint-down(lg){
                width: 50vw;
            }
            @include media-breakpoint-down(sm){
                width: 70vw;
            }
        }
        .container {
            position: relative;
            z-index: 1;
        }
    }
    &__legend {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        gap:1.5rem;
        @include media-breakpoint-down(lg){
            grid-template-columns: 1fr 1fr;
        }
        @include media-breakpoint-down(sm){
            grid-template-columns: 1fr;
        }
        &-item {
            &-number {
                width: 32px;
                height: 32px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
    &__footer {
        position: relative;
        .container {
            position: relative;
            z-index: 1;
        }
        &::before {
            content: "";
            width: 80vw;
            height: 100%;
            right: 0;
            top: 0;
            position: absolute;
            background: linear-gradient(280deg, #21cbbb 0%, #022A32 90%);
            z-index: 0;
            @include media-breakpoint-down(lg){
                width: 100vw;
            }
        }
    }
}
@keyframes triangleftIN {
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(0);
    }
}
@keyframes trianglerightIN {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0%);
    }
}
@keyframes triangleftOUT {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-100%);
    }
}
@keyframes trianglerightOUT {
    from {
        transform: translateX(0%);
    }
    to {
        transform: translateX(100%);
    }
}
.triangles-figure{
    
    .full-width-figure__anim-img{
        @include media-breakpoint-down(sm){
            max-width: 225vw;
        }
        &.--1{
            animation-name: triangleftOUT;
            animation-duration: 1.5s;
            animation-fill-mode: forwards;
        }
        &.--2{
            animation-name: trianglerightOUT;
            animation-duration: 1.5s;
            animation-fill-mode: forwards;
        }
    }
    &.visible{
        .full-width-figure__anim-img{
            &.--1{
                animation-name: triangleftIN;
                animation-duration: 1.5s;
                animation-fill-mode: forwards;
            }
            &.--2{
                animation-name: trianglerightIN;
                animation-duration: 1.5s;
                animation-fill-mode: forwards;
            }
        }
    }
    
}
.lp-piazza-giardino{
    &__header{
        position: relative;
        &__anim-img{
            position: absolute;
            top: 0;
            right: -25%;
            animation-name: trianglerightOUT;
            animation-duration: 1.5s;
            animation-fill-mode: forwards;
            @include media-breakpoint-down(md){
                top: 40%;
            }
        }
    }
    &__footer{
        position: relative;
        &__anim-img{
            position: absolute;
            top: 0;
            left: -5%;
            animation-name: triangleftOUT;
            animation-duration: 1.5s;
            animation-fill-mode: forwards;
            @include media-breakpoint-down(xl){
                display: none;
            }
        }
    }
    background: linear-gradient(180deg, rgba(2,42,50,1) 0%, rgba(50,4,1,1) 75%, rgba(64,3,1,1) 100%);
    &__map{
        padding: rem(150px) 0;
        @include media-breakpoint-down(lg){
            padding: rem(80px) 0;
        }
        position: relative;
        figure {
            position: relative;
            z-index:1;
        }
        &-mask {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 0;
            display: flex;
            align-items: center;
            justify-content: center;

            span {
                width: 100%;
                height: 100%;
                background: radial-gradient(circle, rgba(153,204,0,1) 15%, rgba(51,4,0,0) 50%);
                transform: scale(0.75);
                transition: .5s ease-in all;
            }
        }
        &-wrapper {
            img {
                height: 80vh;
                width: auto;
                @include media-breakpoint-down(lg){
                    height: auto;
                    width: 100%;
                }
            }
        }
        &.visible {
            .lp-piazza-giardino__map-mask span {
                transform: scale(1);
                transition: .5s ease-out all;
                @include media-breakpoint-down(lg){
                    transform: scale(1.5);
                }
            }
            
        }
    }
    &__content{
        &.visible{
            .lp-piazza-giardino__header__anim-img{
                animation-name: trianglerightIN;
                animation-duration: 1.5s;
                animation-fill-mode: forwards;
            }
            .lp-piazza-giardino__footer__anim-img{
                animation-name: triangleftIN;
                animation-duration: 1.5s;
                animation-fill-mode: forwards;
            }
        }
    }
    
}

.lp-uffici {
    background: linear-gradient(180deg, rgba(51,4,0,1) 0%, rgba(51,33,3,1) 75%, rgba(2,42,50,1) 100%);
    overflow: hidden;

    @keyframes uffFade {
        from {
            transform: translateX(-100vw);
        }
        to {
            transform: translateX(0%);
        }
    }
    @keyframes uffFadeReverse {
        from {
            transform: translateX(0%);
        }
        to {
            transform: translateX(-100vw);
        }
    }

    &.visible {
        .lp-uffici__footer-squares span,
        .lp-uffici__header-squares span {
            animation-name: uffFade;
            animation-duration: 1.5s;
            animation-fill-mode: forwards;
        }
    }
    &__header {
        &-squares {
            
            span {
                width: 20%;
                height: 50%;
                position: absolute;
                z-index: 0;
                transform: rotate(-45deg);
                animation-name: uffFadeReverse;
                animation-duration: .5s;
                animation-fill-mode: forwards;
                
                
                @include media-breakpoint-down(lg){
                    width: 25%;
                    margin-top: 30%;
                }
                @include media-breakpoint-down(sm){
                    width: 50%;
                    margin-top: 8%;
                }

                &:nth-of-type(1){
                    left: 20%;
                    background: linear-gradient(#4b1e00 0%, #e6ac00 100%);
                    top: 50%;
                    @include media-breakpoint-down(lg){
                        left: 0%;  
                    }
                    
                }
                &:nth-of-type(2){
                    left: 40%;
                    background: linear-gradient(#e6ac00 0%, #4b1e00 100%);
                    top: 0;
                    @include media-breakpoint-down(lg){
                        left: 25%;  
                    }
                    @include media-breakpoint-down(sm){
                        left: 50%;
                    }
                }
                &:nth-of-type(3){
                    left: 60%;
                    background: linear-gradient(#4b1e00 0%, #e6ac00 100%);
                    top: 50%;
                    @include media-breakpoint-down(lg){
                        left: 50%;  
                    }
                    @include media-breakpoint-down(sm){
                        display: none;
                    }
                }
                &:nth-of-type(4){
                    left: 80%;
                    background: linear-gradient(#e6ac00 0%, #4b1e00 100%);
                    top: 0;
                    @include media-breakpoint-down(lg){
                        left: 75%;  
                    }
                    @include media-breakpoint-down(sm){
                        display: none;
                    }
                }
            }
        }
        .container {
            position: relative;
            z-index: 2;
        }
    }
    &__footer {
        position: relative;
        &-squares {
            span {
                width: 20%;
                height: 50%;
                position: absolute;
                z-index: 0;
                transform: rotate(-45deg);
                animation-name: uffFadeReverse;
                animation-duration: .5s;
                animation-fill-mode: forwards;

                &:nth-of-type(1){
                    left: 0%;
                    background: linear-gradient(#e6ac00 0%, #4b1e00 100%);
                    top: 00%;
                }
                
            }
        }
        .container {
            position: relative;
            z-index: 2;
        }
    }
}

.lp-architettura {
    &__footer {
        position: relative;
        .container {
            position: relative;
            z-index: 1;
        }
        &::before {
            content: "";
            width: 30vw;
            height: 100%;
            left: 0;
            top: 0;
            position: absolute;
            background: linear-gradient(90deg, #21cbbb 0%, #022A32 90%);
            z-index: 0;
            @include media-breakpoint-down(lg){
                width: 100vw;
            }
        }
    }
    &__closure {
        [class^='col'] {
            position: relative;
            
            &:nth-of-type(1),
            &:nth-of-type(2){
                border-bottom: 1px solid $primary;
            }

            @include media-breakpoint-up(md){
                &:nth-of-type(1),
                &:nth-of-type(3){
                    border-right: 1px solid $primary;
                }
            }
            @include media-breakpoint-down(md){
                border-bottom: 1px solid $primary;
                img {
                    max-height: 60px;
                }
                &::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    height: 100%;
                    background-color: $primary;
                    width: 1px;
                }

                &:nth-of-type(2),
                &:nth-of-type(4){
                    padding-left: 12%;
                    &::before {
                        left: 8%;
                    }
                }
                &:nth-of-type(1),
                &:nth-of-type(3){
                    padding-right: 12%;
                    &::before {
                        right: 8%;
                    }
                }
            }
        }
    }
}

.lp-facilities {
    background: linear-gradient(180deg, rgba(64,3,1,1) 0%, rgba(51,4,0,1) 100%);
    @keyframes facilFade {
        from {
            transform: translateX(-100vw);
        }
        to {
            transform: translateX(0%);
        }
    }
    @keyframes facilFadeReverse {
        from {
            transform: translateX(0%);
        }
        to {
            transform: translateX(-100vw);
        }
    }
    &.visible {
        .lp-facilities__content-squares span,
        .lp-facilities__footer-squares span {
            animation-name: facilFade;
            animation-duration: 2s;
            animation-fill-mode: forwards;
        }
        .lp-facilities__footer-squares span {
            animation-duration: 1.5s;
        }
    }
    &__content {
        &-squares {
            span {
                position: absolute;
                z-index: 1;
                width: 20%;
                height: 100%;
                background: linear-gradient(90deg, #ca1614 0%, rgba(50, 4, 1, 0) 100%);

                &:nth-of-type(1){
                    left: 0;
                }
                &:nth-of-type(2){
                    left: 20%;
                }
                &:nth-of-type(3){
                    left: 40%;
                }
                &:nth-of-type(4){
                    left: 60%;
                }
                &:nth-of-type(5){
                    left: 80%;
                }
            }
        }
        .container {
            position: relative;
            z-index: 2;
        }
    }
    &__footer {
        &-squares {
            span {
                position: absolute;
                z-index: 1;
                width: 10%;
                height: 100%;
                background: linear-gradient(90deg, #ca1614 0%, rgba(50, 4, 1, 0) 100%);

                animation-name: facilFadeReverse;
                animation-duration: 1.5s;
                animation-fill-mode: forwards;

                @include media-breakpoint-down(lg){
                    width: 20%;
                }

                &:nth-of-type(1){
                    left: 0;
                }
                &:nth-of-type(2){
                    left: 20%;
                    @include media-breakpoint-down(sm){
                        display: none;
                    }
                }
            }
        }
        .container {
            position: relative;
            z-index: 2;
        }
    }
}

.floating-form{
    position: fixed;
    top: 50%;
    right: 0;
    transform: translate(100%,-50%);
    z-index: 9999;
    transition: .3s ease-in-out;
    &.active{
        transform: translate(0%,-50%);
    }
    &__content{
        position: relative;
        z-index: 10;
        border-radius: rem(20px) 0 0 rem(20px) ;
        width: rem(900px);
        max-width: 90vw;
        max-height: 85vh;
        overflow-y: auto;
    }
    &__trigger{
        z-index: 0;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(-50%, -50%);
        cursor: pointer;
        &-box{
            cursor: pointer;
            background: $white;
            height: rem(70px);
            width: rem(70px);
            border-radius: 0 0 0 rem(10px);
            transform: rotate(45deg);
            @include media-breakpoint-up(lg){
                height: rem(105px);
                width: rem(105px);
            }
        }
        &-icon{
            position: absolute;
            top: 50%;
            left: 0;
            transform: translate(.5rem,-50%);
            height: 20px;
            width: 20px;
            @include media-breakpoint-up(lg){
                height: rem(26px);
                width: rem(26px);
            }
            content: "";
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18.961' height='14.898' viewBox='0 0 18.961 14.898'%3E%3Cpath id='Icon_ionic-md-mail' data-name='Icon ionic-md-mail' d='M19.405,5.625H4.056A1.811,1.811,0,0,0,2.25,7.431V18.717a1.811,1.811,0,0,0,1.806,1.806h15.35a1.811,1.811,0,0,0,1.806-1.806V7.431A1.811,1.811,0,0,0,19.405,5.625ZM19.18,9.462l-7.449,4.966L4.282,9.462V7.657l7.449,4.966L19.18,7.657Z' transform='translate(-2.25 -5.625)' fill='%23002a33'/%3E%3C/svg%3E%0A");
            .active &{
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14.624' height='11.36' viewBox='0 0 14.624 11.36'%3E%3Cpath id='Tracciato_13680' data-name='Tracciato 13680' d='M.112,0h2.96l3.76-3.92a4.8,4.8,0,0,0,.56-.688h.032A4.151,4.151,0,0,0,8-3.92L11.7,0h3.04L9.008-5.872l5.408-5.488H11.44L8-7.744a3.807,3.807,0,0,0-.56.656H7.408a3.807,3.807,0,0,0-.56-.656L3.392-11.36H.4L5.856-5.84Z' transform='translate(-0.112 11.36)' fill='%23002a33'/%3E%3C/svg%3E%0A");
            }
        }
    }
}

.form-control{
    background: $white;
    border-radius: 0;
    border: 1px solid #707070;
    &:focus{
        background: $white
    }
}

.render-360{
    height: 400px;
    @include media-breakpoint-up(xl){
        height: 750px;
    }
}